


















































































  import { Validator } from '@/constant/Mixins';
  import { ServerResponse } from '@/services/response.types';
  import { CityModel, ProvinceModel } from '@/store/location/types';
  import { InsuranceTypeModel, ProductModel, ProviderModel } from '@/store/product/types';
  import { DetailTransactionModel, ProfileTransactionModel, TransactionLogModel, TransactionModel } from '@/store/transaction/types';
  import Component from 'vue-class-component';
  import { Mixins } from 'vue-property-decorator';
  import { Action, State } from 'vuex-class';
  import filter from '@/constant/Filters';

  @Component({
    name: 'transactionDetail',
    components: {},
    filters: { ...filter, ...{
      filterNull(value:any){
        if(value) return value.toUpperCase() === "NULL"?"":value
        else return ""
      }
    } },
  })
  export default class transactionDetail extends Mixins(Validator) {
    @Action('GetTransactionDetail', { namespace: 'transaction' }) GetTransactionHistory!: (trx_id: string) => Promise<ServerResponse>;
    @Action('GetProvince', { namespace: 'location' }) GetProvince!: (params?: any) => Promise<ServerResponse>;
    @Action('GetCity', { namespace: 'location' }) GetCity!: (params?: any) => Promise<ServerResponse>;

    @State('province', { namespace: 'location' }) province!: ProvinceModel[];
    @State('city', { namespace: 'location' }) city!: CityModel[];
    @State('detail_hisory', { namespace: 'transaction' }) detail_hisory!: DetailTransactionModel;

    public get user(): ProfileTransactionModel {
      return this.detail_hisory.profile;
    }

    public get product(): ProductModel {
      return this.detail_hisory.product;
    }

    public get provider(): ProviderModel {
      return this.detail_hisory.provider;
    }

    public get transaction(): TransactionModel {
      return this.detail_hisory.transaction;
    }

    public get insurance_type(): InsuranceTypeModel {
      return this.detail_hisory.insurance_type;
    }

    public get userProvince(): ProvinceModel {
      return this.province.filter((item) => String(item.state_id) === String(this.user.state_id))[0]??new ProvinceModel;
    }

    public get userCity(): CityModel {
      return this.city.filter((item) => String(item.city_id) === String(this.user.city_id))[0]??new CityModel;
    }


    public get administrationSummary() : number {
      return parseFloat(this.transaction.surcharge) + parseFloat(this.transaction.handling_fee)
    }


    public get totalDiscount() : number {
      return parseFloat(this.transaction.additional_discount_amount) + parseFloat(this.transaction.discount_amount)
    }


    public get lastLog() : TransactionLogModel|null {
      return this.detail_hisory?.log?.sort((a,b)=>Number(a.log_id) - Number(b.log_id)).reverse()[0]
    }


    public steps = [
      {
        date: '17 Jan 2022',
        title: 'Pesanan dibuat',
        show_date: true,
      },
      {
        date: '17 Jan 2022',
        title: 'Pesanan dibuat',
        show_date: true,
      },
      {
        date: '17 Jan 2022',
        title: 'dikirim',
        show_date: false,
      },
      {
        date: '17 Jan 2022',
        title: 'sampai tujuan',
        show_date: false,
      },
    ];

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      this.GetProvince()
      this.GetCity

      let transaction_id = this.$route.params.transaction_id as string
      if (transaction_id) {
        this.GetTransactionHistory(transaction_id)
      } else this.$router.push({ name: 'transaction-history' });
    }
  }
